import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { Navigation } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { CacheService } from "src/app/core/services/cache.service";
import { RouteService } from "src/app/core/services/route.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Output() toggleEvent = new EventEmitter<boolean>();
  sidebarDesktop: boolean = false;
  sidebarMobile: boolean = false;
  navigation: Array<Navigation> = [];
  logoSRCLG: string = "assets/images/cgi_logo.png";
  logoSRCSM: string = "assets/images/cgi_logo.png";
  routeLink: string = "/";
  user: any;
  username: any;
  useremail: any;
  userRole: string = "";
  titleToIconMap: { [title: string]: string } = {
    Imports: "fe-upload",
    Fields: "fe-plus-square",
    Relationships: "fe-users",
    Reports: "fe-pie-chart",
    Forms: "fe-file-text",
    "Notifications, Subscriptions & Webhooks": "fe-bell",
    "View Table": "fe-eye",
    "Table Settings": "fe-settings",
  };

  constructor(
    private authService: AuthService,
    private renderer: Renderer2,
    private cache: CacheService,
    private route: RouteService
  ) {}

  ngOnInit(): void {
    this.fetchUser();
  }

  logout = () => this.authService.logout();

  /**
   * Toggle sidebar for mobile devices
   */
  toggleMobile = () => (this.sidebarMobile = !this.sidebarMobile);

  /**
   * Toggle sidebar for desktops
   * Renderer is used to set class on body as angular can't access it
   */
  toggleDesktop = () => {
    this.sidebarDesktop = !this.sidebarDesktop;
    if (this.sidebarDesktop)
      this.renderer.addClass(document.body, "mini-navbar");
    else this.renderer.removeClass(document.body, "mini-navbar");
    this.toggleEvent.emit(this.sidebarDesktop);
  };

  fetchUser = async () => {
    this.user = await this.cache.getProfile();
    this.userRole = this.user.ApplicationRoles.find(
      (a: any) => a.Name != ""
    ).Name;
  };

  goToProfile = () => this.route.navigate(["/profile"]);
}
