// export const environment = {
//   production: false,
//   applicationId: 914,
//   applicationKey: "x8xvllag1",
//   roleKey: "cxcbo59a1",
//   apiURL: "https://devapi.ignatius.io/api/",
//   secretKey: "x/A?D*G-KaPdSgVkYp3s6v9y$B&E)H+M",
//   client_id: "IBIS",
//   captchaSiteKey: "6LeX0EUaAAAAACwj0vJ36bk1cJyK-OAG3XgwWASU",
//   deploymentURL: "ar-rural-connect-dev.herokuapp.com",
//   communityRole: "cxcbo59a1",
//   ispRole: "vps21tqw5",
// };

export const environment = {
  production: true,
  applicationId: 11,
  applicationKey: "64ivhtjw6",
  roleKey: "htggn4eq3",
  apiURL: "https://api.ignatius.io/api/",
  secretKey: "x/A?D*G-KaPdSgVkYp3s6v9y$B&E)H+M",
  client_id: "BBPRO",
  captchaSiteKey: "6LeX0EUaAAAAACwj0vJ36bk1cJyK-OAG3XgwWASU",
  deploymentURL: "broadband-pro-dev-28e0072592c4.herokuapp.com",
  communityRole: "htggn4eq3",
  ispRole: "j13sfg8g1",
  captchaSiteKeyV3: "6Lc2Z7UdAAAAAL8HwkjdbWS1OsNKBv2aUvlN1clV",
};
